.editSeat {
  margin-left:180px;
  margin-right:180px;
}

.ll2 {
  padding-right:20px;
  vertical-align:middle;
  padding-top:5px;
  width:120px;
  background:lightgrey;
}

.editSeat td {
  border:none;
  /*width:50%;*/
}

.lotActive {
  width: 70px;
  height: 50px;
  background-color:lightgrey;
  border:1px solid white;
  color:green;
  /*padding:10px;*/
}

.lotDeleted {
  width: 70px;
  height: 50px;
  background-color:lightgrey;
  border:1px solid white;
  color:red;
  /*padding:10px;*/
}

.lotSold {
  width: 70px;
  height: 50px;
  background-color:brown;
  border:1px solid white;
  color:white;
  /*padding:10px;*/
}

.tableOfSeat {
  width: 100%;
  margin-top:20px;
  padding-left:180px;
  height:80%;
  overflow-y: auto;
  max-height: 400px;
}
