.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin:0;
  /*border:none;*/
}


p {
  margin-top: 0.30em ;
  margin-bottom: 0.30em ;
}

/*to get 100%, we need to include div into the list
with html, and body, but this disables the editor,
// like the editor is in a loop of layout/??
//maybe we get rid of the editor
// and just use plain text field???
*/
html, body {
  height: 100%;
  min-width:1000px;
  /*width: 90%;*/
  
  margin: auto;
  /*position:fixed;*/
}

div {
  /*height: auto; 100%; /*400px;*/
  box-sizing: border-box;
  margin:0;
  padding: 0;
  margin-left:0;
  padding-left:0;
  scrollbar-width: thin;
  border:none;
}

html, body {
  /*overflow: hidden;*/
}

.headerTable {
  padding-left: 200px;
  padding-right: 200px;
  width: 100%;
  border-bottom: 1pt solid brown
}

.headerTd {
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px;
  width:25%;
  border:none;
}

.account {
  color: white;
  font-size: 13px;
  float: right;
  margin-right: 15%;
  position: relative;
  top: -25px;
  margin-bottom: -25px;
}

a:link {
  /*color: black;*/
  background-color: transparent;
  /*margin-right: 2.3em; */
  text-decoration: none;
}

.headerDiv {
  height: auto; /*110px;*/
  /*padding-left: 5%;
  padding-right:5%;*/
  width: 100%;
  /*border-bottom: solid #D3D3D3 1px;*/
}

