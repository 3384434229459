h2 {
  margin-top:20px;
  text-align:center;
}

h3 {
  margin-top:20px;
  text-align:center;
}

table{
  margin-left:auto;
  margin-right:auto;
}

.Account {
  text-align:center;
}

.setInfo{
  text-align:center;
}

.Params {
  text-align:right;
}

.instruction {
  margin-left:400px;
  margin-right:400px;
  font-size:12px;
  text-align:left;
  color:brown;
}

input {
  background-color:lightblue;
  color:black;
  font-size:18px;
}
