th{
  background-color:darkblue;
  border:1px solid white;
  color:white;
  padding:10px;
}

td{
  border:1px solid grey;
}

.daySeat {
  margin-left: auto; /*30px;*/
  margin-right: auto; /*30px;*/
  margin-top: 10px;
  border-spacing:0px;
  border-collapse:collapse
}

.PurchaseSeat {
  text-align:center;
}

.PurchaseSeat td {
  height: 40px;
  border:1px solid grey;
}

