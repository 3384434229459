
.calendar
{
  height:450px;
  background-color: white;
  /*padding: 20px;*/
  margin-left:200px;
  margin-right:200px;
  border:1px grey solid;
  /*box-shadow: 0px 5px 10px rgba(0,0,0,0.4);*/
}

.calendar .dropdown
{
    display: none;
    position: absolute;
    background-color: #fff;
    color: #1caff6;
    text-align: center;
    font-size: 14pt;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    width: 160px;
    left: 0px;
    z-index: 2000;
}

.calendar .dropdown .dropdown-item
{
    cursor:pointer;
    opacity: .7;
    transition: .5s opacity;
}

.calendar .dropdown .dropdown-item:hover
{
    opacity: 1;
}

.calendar .years
{
    display:none;
}

.calendar .title
{
    text-align:center;
    font-size:20pt;
}

.calendar .calendar-btn
{
    float: left;
    background-color: #24aeff;
    color: white;
    text-align: center;
    font-size: 14pt;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    width: 20%;
    cursor: pointer;
    transition: .5s background-color;
}

.calendar .calendar-btn:hover{
    background-color:#1f71a1;
}

.calendar .year-btn
{
    float:right;
}

.calendar .calendar-dates .days .day
{
    float:left;
    width: 14.28%;
    /*margin: 1%; */
    font-size:9pt;
    /*text-align:center;*/
    text-align:right;
    /*border-radius: 10px;*/
    border: solid 1px #ddd;
}

.calendar .calendar-dates .days .day.date {
  /*padding: 0;*/
  /*padding-top:8%;*/
  height:62px;
  padding-top:5px;
  padding-right:5px;
  /*min-width:110px;*/
  font-weight:bold;
}

.calendar .calendar-dates .days .day.date.over {
    color: grey;
}

.calendar .calendar-dates .days .day.blank
{
    background-color: white;
    border:none;
}

.calendar .calendar-dates .days .day.selected
{
    background-color: #1caff6;
    color: white;
    cursor:pointer;
    opacity: .5;
    transition: .5s opacity;
}

.calendar .calendar-dates .days .day.selected:hover
{
    opacity: 1;
}

.calendar .calendar-dates .days .day.label
{
    font-size:13pt;
    height: 40px;
    background-color: white;
    color: black;
    border:none;
    font-weight:bold;
    padding: 1%;
}

.clear{
    clear:both;
}

.detailButton {
  width: 20px;
  height: 30px;
  border: none;
  padding: 0;
  margin-left: 4px;
  color: white
}

.detailButton.greenButton {
  background-color: green;
}

.detailButton.brownButton {
  background-color: brown;
}

.detailButton.dbButton {
  width:30%; /*50px;*/
  min-wdith:50px;
  background-color: darkblue;
}

/*
@media only screen and (max-width: 960px) {
    .calendar{
        width: 100%;
        margin: 0px;
        margin: 0px;
        box-sizing: border-box;
        position: relative;
        left: 0px;
    }
}
*/